<template>
    <v-container fluid>
        <v-form @submit.prevent="savePage">
            <ValidationObserver ref="observer" v-slot="{ invalid, validated, passes, validate }">
                <v-card flat>
                    <v-card-text>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-row class="flex-nowrap">
                                    <v-col cols="1" style="max-width: 80px">
                                        <v-btn :to="{name: 'page'}" class="back-btn" link large>
                                            <v-icon>mdi-less-than</v-icon>
                                        </v-btn>
                                    </v-col>
                                    <v-col cols="11">
                                        <v-subheader class="headline">{{ heading }}</v-subheader>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col class="py-0" cols="12" md="6">
                                <v-tabs v-model="tab" background-color="transparent"
                                        :right="$vuetify.breakpoint.mdAndUp"
                                        :grow="$vuetify.breakpoint.smAndDown"
                                        color="deep-purple accent-4" @change="setLanguage" class="pb-4">
                                    <v-tab ref="language" v-for="language in languages" :key="language">
                                        <span :class="exist_translations[language] ? '' : 'red--text '">
                                            {{ language }}
                                        </span>
                                    </v-tab>
                                </v-tabs>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0" cols="12" >
                                <v-row>
                                    <v-col cols="12" class="pt-0">
                                        <ValidationProvider ref="title" rules="required|min:1|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="title" type="text"
                                                          prepend-icon="mdi-format-title"
                                                          :error-messages="errors" :disabled="loading"
                                                          :label="$t('page_title')" color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12" class="pt-0">
                                        <ValidationProvider ref="slug" rules="required|alpha_latin_num_minus|min:1|max:255"
                                                            v-slot="{ errors, valid }">
                                            <v-text-field v-model="slug" type="text"
                                                          prepend-icon="mdi-menu"
                                                          :error-messages="errors" :disabled="loading"
                                                          :label="$t('menu_slug')" color="primary" clearable>
                                            </v-text-field>
                                        </ValidationProvider>
                                    </v-col>
                                    <v-col cols="12">
                                        <ValidationProvider ref="textProvider" name="text"
                                                            rules="required|min:10|max:65535"
                                                            v-slot="{ errors, valid }">
                                            <tinymce-editor id="text" v-model="text" ref="tinymce"
                                                            :init="tinymceInit"></tinymce-editor>
                                            <div v-show="!valid" class="mt-2" style="color: red">
                                                {{ errors[0] ? errors[0] : $t('description_is_not_filled_out')
                                                }}
                                            </div>
                                        </ValidationProvider>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" class="pt-0">
                                <ValidationProvider ref="title_seo" rules="required|min:1|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-text-field v-model="title_seo" type="text"
                                                  :error-messages="errors" :disabled="loading"
                                                  :label="$t('page_seo_title')" color="primary" clearable>
                                    </v-text-field>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" class="pt-0 pb-1">
                                <ValidationProvider ref="keywords" rules="required|min:1|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-textarea v-model="keywords"
                                                :error-messages="errors" :disabled="loading"
                                                :label="$t('page_seo_keywords')"
                                                rows="5" row-height="15" auto-grow outlined>
                                    </v-textarea>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" class="pt-0 pb-1">
                                <ValidationProvider ref="description" rules="required|min:1|max:255"
                                                    v-slot="{ errors, valid }">
                                    <v-textarea v-model="description"
                                                :error-messages="errors" :disabled="loading"
                                                :label="$t('page_seo_description')"
                                                rows="5" row-height="15" auto-grow outlined>
                                    </v-textarea>
                                </ValidationProvider>
                            </v-col>
                            <v-col cols="12" class="py-0">
                                <v-card flat light outlined elevation="3">
                                    <v-card-title class="subtitle-1 font-weight-medium py-1 primary white--text">
                                        {{ $t('seo_search_engine_page_preview') }}
                                    </v-card-title>
                                    <v-divider></v-divider>
                                    <v-card-text class="pa-0">
                                        <v-card flat light elevation="0">
                                            <v-card-title v-if="title_seo" class="pt-2 title blue--text">
                                                {{title_seo }}
                                            </v-card-title>
                                            <v-card-title v-if="!title_seo" class="pt-2 title blue--text">
                                                {{title_product }}
                                            </v-card-title>
                                            <v-card-subtitle class="pb-1 green--text">{{ slug }}</v-card-subtitle>
                                            <v-card-text v-if="description">{{ description }}</v-card-text>
                                            <v-card-text v-if="!description">
                                                Описание для поисковых машин.
                                            </v-card-text>
                                        </v-card>
                                    </v-card-text>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col class="py-0 d-flex justify-start" cols="12" sm="6">
                                <v-switch v-model="deleted" :disabled="loading"
                                          :label="$t('deleted')"
                                          @change="deleted ? active = false : active"
                                          color="red" hide-details></v-switch>
                            </v-col>
                            <v-col class="py-0 d-flex justify-start justify-sm-end" cols="12" sm="6">
                                <v-switch v-model="active" :disabled="loading || deleted"
                                          :label="$t('published')"
                                          color="primary" hide-details></v-switch>
                            </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="px-4 py-4">
                        <v-progress-linear v-show="$vuetify.breakpoint.smAndUp" v-model="progress"
                                           :active="loading" class="mx-2"></v-progress-linear>
                        <v-spacer v-show="$vuetify.breakpoint.smAndUp"></v-spacer>
                        <v-btn type="submit" :disabled="invalid || loading" :loading="loading"
                               :block="$vuetify.breakpoint.xs" color="primary">
                            {{ $t('save') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </ValidationObserver>
        </v-form>
    </v-container>
</template>
<script>

import {ValidationObserver, ValidationProvider} from 'vee-validate'
import {mapGetters} from "vuex"
import TinymceEditor from '@tinymce/tinymce-vue'

export default {
    name: 'PageForm',
    components: {
        ValidationProvider,
        ValidationObserver,
        TinymceEditor
    },
    inject: ['forceRerender'],
    data() {
        return {
            heading: null,
            progress: 0,
            loading: false,
            tab: 0,
            language: null,
            errors: {},
            id: null,
            active: false,
            deleted: false,
            text: null,
            description: null,
            title: null,
            title_seo: null,
            keywords: null,
            title_product: 'Страница Алматы: продажа  ...',
            slug: null,
            exist_translations: {},
            all_translations: true,
        }
    },
    computed: {
        ...mapGetters(['listLanguages', 'lang', 'defaultLogo']),
        languages() {
            return this.listLanguages
        },
        tinymceInit() {
            return this.tinymceInitDefault()
        }
    },
    mounted() {
        this.language = this.languages[this.tab]
        this.checkCreate()
    },
    methods: {
        checkCreate() {
            if (this.$route.name === "page.create") {
                this.heading = this.$t('page_creation')
            } else {
                this.heading = this.$t('page_editing')
                if (this.$route.params.id) {
                    this.getPage()
                }
            }
        },
        setLanguage(val) {
            this.language = this.languages[val]
            this.checkCreate()
        },
        async getPage() {
            var _this = this
            this.progress = 0
            this.loading = true
            let params = {}
            if (this.language) {
                params.language = this.language
            }
            await this.$http
                .get(`admin/page/${this.$route.params.id}`, {
                    params: params,
                    progress(e) {
                        if (e.lengthComputable) {
                            _this.progress = Math.round((e.loaded / e.total) * 100)
                        }
                    }
                })
                .then(res => {
                    this.slug = res.body.data.slug
                    this.active = res.body.data.active
                    this.deleted = res.body.data.deleted
                    this.text = res.body.data.text
                    this.description = res.body.data.description
                    this.title_seo = res.body.data.title_seo
                    this.title = res.body.data.title
                    this.keywords = res.body.data.keywords
                    this.exist_translations = res.body.data.exist_translations
                    this.all_translations = res.body.data.all_translations
                })
                .catch(err => {
                    this.$toastr.error(this.$t('failed_to_get_page'))
                })
                .finally(end => {
                    this.progress = 0
                    this.loading = false
                })
        },
        async savePage() {
            var _this = this
            this.progress = 0
            this.loading = true
            var formData = new FormData()
            if (this.language) {
                formData.append('language', this.language)
            }

            if (this.slug) {
                formData.append('slug', this.slug)
            }

            if (this.deleted) {
                formData.append('deleted', 1)
            }

            if (this.active) {
                formData.append('active', 1)
            }

            if (this.title) {
                formData.append('title', this.title)
            }

            if (this.text) {
                formData.append('text', this.text)
            }
            if (this.description) {
                formData.append('description', this.description)
            }

            if (this.title_seo) {
                formData.append('title_seo', this.title_seo)
            }

            if (this.keywords) {
                formData.append('keywords', this.keywords)
            }

            if (this.$route.params.id) {
                // Save
                await this.$http
                    .put(`admin/page/${this.$route.params.id}`, formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('page_has_been_updated'))
                        if (res && res.body && res.body.data && res.body.data.exist_translations) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('page_has_not_been_updated'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            } else {
                // Add
                await this.$http
                    .post('admin/page', formData, {
                        headers: {
                            'Content-Type': 'multipart/form-data'
                        },
                        progress(e) {
                            if (e.lengthComputable) {
                                _this.progress = Math.round((e.loaded / e.total) * 100)
                            }
                        }
                    })
                    .then(res => {
                        this.$toastr.success(this.$t('page_has_been_added'))
                        if (res && res.body && res.body.data && res.body.data.id) {
                            this.exist_translations = res.body.data.exist_translations
                            this.all_translations = res.body.data.all_translations
                            this.$router.push({
                                name: 'page.edit',
                                params: {
                                    id: res.body.data.id
                                }
                            })
                        } else {
                            this.$router.push({
                                name: 'page'
                            })
                        }
                    })
                    .catch(err => {
                        this.$toastr.error(this.$t('page_has_not_been_added'))
                        if (err && err.body && err.body.message) {
                            for (let prop in err.body.errors) {
                                if (hasOwnProperty.call(err.body.errors, prop)) {
                                    if (_this.$refs[prop]) {
                                        _this.$refs[prop].setErrors([
                                            err.body.errors[prop][0]
                                        ])
                                    }
                                }
                            }
                            if (!err.body.errors) {
                                this.$toastr.error(err.body.message)
                            }
                        }
                    })
                    .finally(end => {
                        this.progress = 0
                        this.loading = false
                    })
            }
        }
    }
}
</script>
